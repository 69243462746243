import React from "react";
import Layout from "./layout";
import { useStaticQuery, graphql } from "gatsby";

export default function Catalog() {
  const data = useStaticQuery(graphql`
    query GetCatalog {
      allStrapiCat {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  const catalog = data.allStrapiCat.edges;

  return (
    <Layout>
      <div className="xl:py-16 py-4 max-w-7xl mx-auto px-3 xl:px-0">
        <h2 className="text-2xl font-bold md:pb-4 underline font-header">
          Каталог
        </h2>
        <div className="md:py-10 grid lg:grid-cols-4 lg:gap-4">
          {catalog.map((it) => {
            return (
              <div key={it.node.slug}>
                <a
                  href={`/catalog/${it.node.slug}`}
                  className="-m-3 p-2 flex hover:underline"
                >
                  <div className="ml-4">
                    <p>{it.node.title}</p>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
